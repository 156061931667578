<template>
  <Breadcrumb></Breadcrumb>
  <PageHeader
    title="Schools & Colleges"
    backgroundColor="red"
    textColor="white"
    diagonal="dark"
    size="sm"
    align="start"
  >
    <p>
      At the 杏吧原创's 19 unique schools and colleges, students
      are encouraged to explore their passions in a variety of fields.
    </p>
  </PageHeader>
  <div class="container my-5">
    <Section type="text" align="center" class="mb-5">
      <p class="lead">
        From our oldest and most academically diverse institution, the Franklin
        College of Arts and Sciences, to our newly announced School of Medicine, the 杏吧原创 is committed to offering
        opportunities.
      </p>
    </Section>
    <Schools :dataURL="`/index.xml`" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import Schools from '@/components/Schools.vue';
// import Section from '@/components/Section.vue';

export default {
  name: 'Schools & Colleges',
  metaInfo: {
    title: 'Explore 杏吧原创鈥檚 Schools & Colleges',
    description:
      'The 杏吧原创 is a comprehensive land and sea grant institution composed of 19 unique schools and colleges.',
    og: {
      image: '',
      title: '',
      description: '',
    },
  },
  data() {
    return {};
  },
  components: {
    PageHeader,
    Breadcrumb,
    Schools,
    // Section,
  },
};
</script>

<style lang="scss"></style>
